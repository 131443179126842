

.bodyHeader {

  --text-primary: #f79071;
  --text-secondary: #fa744f;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
}

.navbarc {
  position: fixed;
  background-color: var(--bg-primary);
  transition: width 600ms ease;
  overflow: hidden;
  display: block;
  font-size: 14px;
  font-family: 'Open Sans';
  left: 0;
}

.navbarc-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.navc-item {
  width: 100%;
}


.navc-link {
  display: flex;
  align-items: center;
  height: 7.5rem;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(1) ;
  transition: var(--transition-speed);
  text-decoration: none;
}



.navc-link :active{
  text-decoration: none;
}
.navc-link :focus{
  text-decoration: none!important;
}


.navc-link:link {
  text-decoration: none;
}
.navc-link:visited {
  text-decoration: none;
}
.navc-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
  text-decoration: none;
}

.link-text {
  display: none;
  margin-left: 1rem;
 
}

.navc-link svg {
  width: 3.5rem;
  min-width: 3.5rem;
  margin: 0 1.5rem;
}


.link-text{
    color: #fa744f;
}
.fa-primary {
  color: #f79071;
}

.fa-secondary {
  color: #fa744f;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

.logoc {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-secondary);
  font-size: 2rem;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logoc svg {
  transform: rotate(0deg);
  transition: var(--transition-speed);
}

.logoc-text
{
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed);
}

.navbarc:hover .logoc svg {
  transform: rotate(-180deg);
}

.navc-item:last-child{
  margin-top: auto;
}
 
/* Small screens */
@media only screen and (max-width: 599px) {
  .navbarc {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 5em;
  }

.navc-item:last-child{
  margin-top: 0;
}

  .logoc {
    display: none;
  }

  .navbarc-nav {
    flex-direction: row;
  }

  .navc-link {
    justify-content: center;
  }

}


/* Large screens */
@media only screen and (min-width: 600px) {
  .navbarc {
    top: 0;
    width: 6.5rem;
    height: 100vh;
  }

  .navbarc:hover {
    width: 18rem;
  }

  .navbarc:hover .link-text {
    display: inline;
  }

  .navbarc:hover .logoc svg
  {
    margin-left: 11rem;
  }

  .navbarc:hover .logoc-text
  {
    left: 0px;
  }
}

